"use client";

import Logo from "@/assets/console-logo.svg";
import LoginModal from "@/components/modals/login-modal";
import { NotificationType, showToastNotification } from "@/utils/notification";
import { getUserEmailFromOAuth } from "@/utils/privy";
import { OAuthTokens, useLogin, useOAuthTokens } from "@privy-io/react-auth";
import { Link, LoginButton } from "@spheron/ui-library";
import KeyIcon from "@spheron/ui-library/dist/assets/key.svg";
import { useRouter } from "next/navigation";
import { useState } from "react";

const Login = () => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const { login } = useLogin({
    onError: (error) => {
      console.log("Error in login", error);
      showToastNotification({
        message: "Something went wrong",
        timestamp: Date.now(),
        type: NotificationType.Error,
      });
    },
    onComplete: async (user) => {
      const address = user?.wallet?.address || "";
      localStorage?.setItem?.("tenantAddress", address);
      router.push(`/${user?.wallet?.address}/marketplace`);
    },
  });

  useOAuthTokens({
    onOAuthTokenGrant: async (tokens: OAuthTokens) => {
      if (tokens.provider === "github") {
        const email = await getUserEmailFromOAuth(tokens.accessToken, "github");
        if (email) {
          localStorage.setItem("email", email);
        }
      }
    },
  });

  return (
    <>
      <LoginModal isOpen={isLoading} setIsOpen={setIsLoading} />
      <div className="flex flex-col gap-y-[80px] items-center pt-[12vh] h-[90vh]">
        <div className="flex flex-col gap-[60px] w-full items-center justify-center">
          <Logo className="dark:text-white text-black h-[70px]" />
          <h1 className="text-center font-700 text-3xl min-[824px]:text-4xl leading-10 min-[824px]:leading-11 text-base-heading-text-color dark:text-dark-base-heading-text-color w-7/12">
            <div>Your Gateway to Seamless </div>
            <div>Compute Power</div>
          </h1>
        </div>
        <div className="py-10 px-14 border border-base-border dark:border-dark-base-border rounded-lg">
          <div className="flex flex-col gap-y-11 items-center justify-center">
            <div className="flex flex-col gap-y-6 justify-center w-full items-center">
              <h3 className="text-xl leading-6 text-base-heading-text-color dark:text-dark-base-heading-text-color">
                Sign Up to Continue
              </h3>
              <LoginButton
                label="Login to Continue"
                socialIcon={
                  <KeyIcon className="w-5 h-5 text-base-icon dark:text-dark-base-icon" />
                }
                handleClick={login}
              />
            </div>
            <p className="text-sm leading-5 text-base-text-color dark:text-dark-base-text-color flex items-center gap-x-1">
              By continuing, you agree to our{" "}
              <Link
                type="secondary"
                size="default"
                text="Terms of Service"
                isBold={false}
                onClick={() => {
                  window.open("/terms-of-service", "_blank");
                }}
              />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
